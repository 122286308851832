.footer {
  max-width: 100vw;
  padding: 180px 120px 60px 120px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 84px;
  .top-footer {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    z-index: 2;
    margin-right: 433px;
  }
  .list,
  .contacts {
    display: flex;
    flex-direction: column;
    &.pages {
      gap: 24px;
    }
    &.follows {
      gap: 4px;
    }
    .link p {
      display: inline;
    }
    .link:hover p {
      color: var(--c9) !important;
    }
  }
  .contacts {
    gap: 32px;
    width: 250px;
  }
  .animated-hand {
    position: absolute;
    right: 200px;
    width: 223px;
    height: 961px;
    z-index: 1;
  }
  .bottom-footer {
    align-self: center;
    z-index: 2;
  }
}

.mob-footer {
  max-width: 100vw;
  padding: 40px 16px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 60px;
  .top-footer {
    display: flex;
    flex-direction: column;
    z-index: 2;
    gap: 60px;
  }
  .list,
  .contacts {
    display: flex;
    flex-direction: column;
    .link p {
      display: inline;
    }
    &.pages {
      gap: 24px;
    }
    &.follows {
      gap: 8px;
    }
  }
  .contacts {
    gap: 24px;
    .list {
      gap: 8px;
    }
  }
  .animated-hand {
    position: absolute;
    right: 0;
    bottom: -50%;
    width: 223px;
    height: 961px;
    z-index: 1;
  }
  .bottom-footer {
    align-self: center;
    z-index: 2;
  }
}
@media screen and (min-width: 960px) and (-webkit-device-pixel-ratio: 1.25) {
  .footer {
    max-width: 125vw;
  }
}
