section.courses {
  width: 100vw;
  .double-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    .double-col {
      position: relative;
      .animated-content {
        position: relative;
        flex: 1;
        .animated-hand {
          position: absolute;
          width: 1046px;
          height: 731px;
          top: 50px;
          left: 50px;
          transform-origin: center left;
        }
      }
      .round {
        position: absolute;
        right: 210px;
        bottom: 150px;
        transform: scaleX(2.7);
      }
    }
    .courses-box {
      padding: 120px;
      .courses-list {
        display: flex;
        flex-direction: column;
        gap: 40px;
        .card {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .course-tag {
            padding: 6px 20px;
            border-width: 1px;
            width: fit-content;
            border-radius: 4px;
            border-style: solid;
            margin-bottom: 16px;
          }
          .link {
            width: fit-content;
            align-self: flex-end;
          }
          .button {
            width: 300px;
          }
          .goodies {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
    .double-box {
      width: 480px;
      margin: 200px 120px 0;
    }
  }
}
@media screen and (max-width: 1440px) {
  section.courses {
    .double-container {
      .double-box {
        margin: 200px 120px 0 50px;
      }
    }
  }
}
@media screen and (min-width: 960px) and (-webkit-device-pixel-ratio: 1.25) {
  section.courses {
    width: 125vw;
  }
}
section.m-courses {
  width: 100vw;
  .double-container {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    .double-box {
      padding: 40px 16px;
    }
    .courses-box {
      padding: 40px 16px;
      .courses-list {
        .card {
          border-radius: 16px;
          padding: 16px;
          display: flex;
          flex-direction: column;
          margin-top: 16px;
          .course-tag {
            padding: 4px 12px;
            border-width: 1px;
            border-style: solid;
            border-radius: 4px;
            width: fit-content;
            margin-bottom: 8px;
          }
          .icon-button {
            transform: rotate(-90deg);
            align-self: flex-end;
          }
          .goodies {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
          }
        }
      }
    }
  }
}
