section.contact-form {
  .block-container {
    padding-top: 150px;
    padding-bottom: 180px;
    min-height: 600px;
    position: relative;
    &.open-secret {
      padding-bottom: 60px;
    }
    .animated-hand {
      position: absolute;
      top: 200px;
      width: 328px;
      height: 1049px;
      left: 220px;
      z-index: -1;
    }
    .animated-hand + .animated-hand {
      transform: scaleX(-1);
      right: 220px;
      left: unset;
    }
    .content {
      width: 1196px;
      margin: 0 auto;
      .contact-form-title {
        width: 480px;
        line-height: 100%;
      }
      .contact-form-desc {
        width: 680px;
        &-wrapper {
          display: flex;
          justify-content: flex-end;
        }
      }
      .form-wrapper {
        margin-top: 96px;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 12px;
        grid-template-areas:
          ". . . . ."
          "inst inst . . .";
        grid-row-gap: 12px;
        .row-2 {
          grid-area: inst;
        }
        .row-2-column-4 {
          grid-column: 4;
        }
        .row-2-column-5 {
          grid-column: 5;
        }
        .submit-button {
          height: 56px;
          svg {
            height: unset;
            width: unset;
          }
        }
        .promo-button {
          height: 56px;
          gap: 0;
          justify-self: flex-end;
          width: 220px;
          svg {
            height: unset;
            width: unset;
          }
          &.transparent {
            opacity: 0.2;
            border: solid 1px var(--w);
            width: 144px;
            &:hover {
              opacity: 0.6;
            }
          }
          &:hover {
            border: 1px solid #fff;
          }
        }
      }
      .secret {
        &-wrapper {
          display: flex;
          justify-content: flex-end;
        }
        &-card {
          margin-top: 32px;
          width: 544px;
          background: var(--w);
          padding: 16px 32px;
          border-radius: 16px;
          .code {
            white-space: nowrap;
          }
          .icon-wrapper {
            width: 32px;
            height: 32px;
            border-radius: 4px;
            &:hover {
              cursor: pointer;
              background-color: var(--c14);
            }
            svg {
              height: unset;
              width: unset;
            }
          }
          .notification {
            padding: 2px 8px;
            border-radius: 4px;
          }
        }
      }
    }
  }
}
section.m-contact-form {
  .block-container {
    padding: 40px 16px;
    position: relative;
    .animated-hand {
      position: absolute;
      top: 200px;
      width: 328px;
      height: 1049px;
      left: 0;
      z-index: -1;
    }
    .form-wrapper {
      row-gap: 16px;
      input {
        width: 100%;
      }
      .promo-button {
        gap: 0;
        padding: 14px 24px;
        &.transparent {
          opacity: 0.2;
          border: solid 1px var(--w);
          width: 144px;
        }
        svg {
          width: 21px;
          height: 21px;
        }
      }
      .secret-card {
        margin: 16px 0;
        background: var(--w);
        border-radius: 16px;
        .notification {
          padding: 2px 8px;
          border-radius: 4px;
          width: fit-content;
        }
        svg {
            width: unset;
            height: unset;
        }
      }
      .button svg {
        width: unset;
        height: unset;
      }
    }
  }
}
