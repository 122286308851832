a {
  text-decoration: none;
}
.fs-8 {
  font-size: 8px;
}
.fs-9 {
  font-size: 9px;
}
.fs-10 {
  font-size: 10px;
}
.fs-11 {
  font-size: 11px;
}
.fs-12 {
  font-size: 12px;
}
.fs-13 {
  font-size: 13px;
}
.fs-14 {
  font-size: 14px;
}
.fs-15 {
  font-size: 15px;
}
.fs-16 {
  font-size: 16px;
}
.fs-17 {
  font-size: 17px;
}
.fs-18 {
  font-size: 18px;
}
.fs-19 {
  font-size: 19px;
}
.fs-20 {
  font-size: 20px;
}
.fs-21 {
  font-size: 21px;
}
.fs-22 {
  font-size: 22px;
}
.fs-23 {
  font-size: 23px;
}
.fs-24 {
  font-size: 24px;
}
.fs-25 {
  font-size: 25px;
}
.fs-26 {
  font-size: 26px;
}
.fs-27 {
  font-size: 27px;
}
.fs-28 {
  font-size: 28px;
}
.fs-29 {
  font-size: 29px;
}
.fs-30 {
  font-size: 30px;
}
.fs-31 {
  font-size: 31px;
}
.fs-32 {
  font-size: 32px;
}
.fs-33 {
  font-size: 33px;
}
.fs-34 {
  font-size: 34px;
}
.fs-35 {
  font-size: 35px;
}
.fs-36 {
  font-size: 36px;
}
.fs-37 {
  font-size: 37px;
}
.fs-38 {
  font-size: 38px;
}
.fs-39 {
  font-size: 39px;
}
.fs-40 {
  font-size: 40px;
}
.fs-41 {
  font-size: 41px;
}
.fs-42 {
  font-size: 42px;
}
.fs-43 {
  font-size: 43px;
}
.fs-44 {
  font-size: 44px;
}
.fs-45 {
  font-size: 45px;
}
.fs-46 {
  font-size: 46px;
}
.fs-47 {
  font-size: 47px;
}
.fs-48 {
  font-size: 48px;
}
.fs-49 {
  font-size: 49px;
}
.fs-50 {
  font-size: 50px;
}
.fs-51 {
  font-size: 51px;
}
.fs-52 {
  font-size: 52px;
}
.fs-53 {
  font-size: 53px;
}
.fs-54 {
  font-size: 54px;
}
.fs-55 {
  font-size: 55px;
}
.fs-56 {
  font-size: 56px;
}
.fs-57 {
  font-size: 57px;
}
.fs-58 {
  font-size: 58px;
}
.fs-59 {
  font-size: 59px;
}
.fs-60 {
  font-size: 60px;
}
.fs-61 {
  font-size: 61px;
}
.fs-62 {
  font-size: 62px;
}
.fs-63 {
  font-size: 63px;
}
.fs-64 {
  font-size: 64px;
}
.fs-65 {
  font-size: 65px;
}
.fs-66 {
  font-size: 66px;
}
.fs-67 {
  font-size: 67px;
}
.fs-68 {
  font-size: 68px;
}
.fs-69 {
  font-size: 69px;
}
.fs-70 {
  font-size: 70px;
}
.fs-71 {
  font-size: 71px;
}
.fs-72 {
  font-size: 72px;
}
.fs-120 {
  font-size: 120px;
}
.ft-pr {
  font-weight: 400;
}
.ft-pi {
  font-style: italic;
}
.ft-pb {
  font-weight: 700;
}
.m0 {
  margin: 0px;
}
.m1 {
  margin: 1px;
}
.m2 {
  margin: 2px;
}
.m3 {
  margin: 3px;
}
.m4 {
  margin: 4px;
}
.m5 {
  margin: 5px;
}
.m6 {
  margin: 6px;
}
.m7 {
  margin: 7px;
}
.m8 {
  margin: 8px;
}
.m9 {
  margin: 9px;
}
.m10 {
  margin: 10px;
}
.m11 {
  margin: 11px;
}
.m12 {
  margin: 12px;
}
.m13 {
  margin: 13px;
}
.m14 {
  margin: 14px;
}
.m15 {
  margin: 15px;
}
.m16 {
  margin: 16px;
}
.m17 {
  margin: 17px;
}
.m18 {
  margin: 18px;
}
.m19 {
  margin: 19px;
}
.m20 {
  margin: 20px;
}
.m21 {
  margin: 21px;
}
.m22 {
  margin: 22px;
}
.m23 {
  margin: 23px;
}
.m24 {
  margin: 24px;
}
.m25 {
  margin: 25px;
}
.m26 {
  margin: 26px;
}
.m27 {
  margin: 27px;
}
.m28 {
  margin: 28px;
}
.m29 {
  margin: 29px;
}
.m30 {
  margin: 30px;
}
.m31 {
  margin: 31px;
}
.m32 {
  margin: 32px;
}
.m33 {
  margin: 33px;
}
.m34 {
  margin: 34px;
}
.m35 {
  margin: 35px;
}
.m36 {
  margin: 36px;
}
.m37 {
  margin: 37px;
}
.m38 {
  margin: 38px;
}
.m39 {
  margin: 39px;
}
.m40 {
  margin: 40px;
}
.m41 {
  margin: 41px;
}
.m42 {
  margin: 42px;
}
.m43 {
  margin: 43px;
}
.m44 {
  margin: 44px;
}
.m45 {
  margin: 45px;
}
.m46 {
  margin: 46px;
}
.m47 {
  margin: 47px;
}
.m48 {
  margin: 48px;
}
.m49 {
  margin: 49px;
}
.m50 {
  margin: 50px;
}
.m51 {
  margin: 51px;
}
.m52 {
  margin: 52px;
}
.m53 {
  margin: 53px;
}
.m54 {
  margin: 54px;
}
.m55 {
  margin: 55px;
}
.m56 {
  margin: 56px;
}
.m57 {
  margin: 57px;
}
.m58 {
  margin: 58px;
}
.m59 {
  margin: 59px;
}
.m60 {
  margin: 60px;
}
.m61 {
  margin: 61px;
}
.m62 {
  margin: 62px;
}
.m63 {
  margin: 63px;
}
.m64 {
  margin: 64px;
}
.p0 {
  padding: 0px;
}
.p1 {
  padding: 1px;
}
.p2 {
  padding: 2px;
}
.p3 {
  padding: 3px;
}
.p4 {
  padding: 4px;
}
.p5 {
  padding: 5px;
}
.p6 {
  padding: 6px;
}
.p7 {
  padding: 7px;
}
.p8 {
  padding: 8px;
}
.p9 {
  padding: 9px;
}
.p10 {
  padding: 10px;
}
.p11 {
  padding: 11px;
}
.p12 {
  padding: 12px;
}
.p13 {
  padding: 13px;
}
.p14 {
  padding: 14px;
}
.p15 {
  padding: 15px;
}
.p16 {
  padding: 16px;
}
.p17 {
  padding: 17px;
}
.p18 {
  padding: 18px;
}
.p19 {
  padding: 19px;
}
.p20 {
  padding: 20px;
}
.p21 {
  padding: 21px;
}
.p22 {
  padding: 22px;
}
.p23 {
  padding: 23px;
}
.p24 {
  padding: 24px;
}
.p25 {
  padding: 25px;
}
.p26 {
  padding: 26px;
}
.p27 {
  padding: 27px;
}
.p28 {
  padding: 28px;
}
.p29 {
  padding: 29px;
}
.p30 {
  padding: 30px;
}
.p31 {
  padding: 31px;
}
.p32 {
  padding: 32px;
}
.p33 {
  padding: 33px;
}
.p34 {
  padding: 34px;
}
.p35 {
  padding: 35px;
}
.p36 {
  padding: 36px;
}
.p37 {
  padding: 37px;
}
.p38 {
  padding: 38px;
}
.p39 {
  padding: 39px;
}
.p40 {
  padding: 40px;
}
.p41 {
  padding: 41px;
}
.p42 {
  padding: 42px;
}
.p43 {
  padding: 43px;
}
.p44 {
  padding: 44px;
}
.p45 {
  padding: 45px;
}
.p46 {
  padding: 46px;
}
.p47 {
  padding: 47px;
}
.p48 {
  padding: 48px;
}
.p49 {
  padding: 49px;
}
.p50 {
  padding: 50px;
}
.p51 {
  padding: 51px;
}
.p52 {
  padding: 52px;
}
.p53 {
  padding: 53px;
}
.p54 {
  padding: 54px;
}
.p55 {
  padding: 55px;
}
.p56 {
  padding: 56px;
}
.p57 {
  padding: 57px;
}
.p58 {
  padding: 58px;
}
.p59 {
  padding: 59px;
}
.p60 {
  padding: 60px;
}
.p61 {
  padding: 61px;
}
.p62 {
  padding: 62px;
}
.p63 {
  padding: 63px;
}
.p64 {
  padding: 64px;
}
.mb0 {
  margin-bottom: 0px;
}
.mr0 {
  margin-right: 0px;
}
.ml0 {
  margin-left: 0px;
}
.mt0 {
  margin-top: 0px;
}
.mh0 {
  margin-right: 0px;
  margin-left: 0px;
}
.mv0 {
  margin-top: 0px;
  margin-bottom: 0px;
}
.pb0 {
  padding-bottom: 0px;
}
.pr0 {
  padding-right: 0px;
}
.pl0 {
  padding-left: 0px;
}
.pt0 {
  padding-top: 0px;
}
.mb2 {
  margin-bottom: 2px;
}
.mr2 {
  margin-right: 2px;
}
.ml2 {
  margin-left: 2px;
}
.mt2 {
  margin-top: 2px;
}
.mh2 {
  margin-right: 2px;
  margin-left: 2px;
}
.mv2 {
  margin-top: 2px;
  margin-bottom: 2px;
}
.pb2 {
  padding-bottom: 2px;
}
.pr2 {
  padding-right: 2px;
}
.pl2 {
  padding-left: 2px;
}
.pt2 {
  padding-top: 2px;
}
.mb4 {
  margin-bottom: 4px;
}
.mr4 {
  margin-right: 4px;
}
.ml4 {
  margin-left: 4px;
}
.mt4 {
  margin-top: 4px;
}
.mh4 {
  margin-right: 4px;
  margin-left: 4px;
}
.mv4 {
  margin-top: 4px;
  margin-bottom: 4px;
}
.pb4 {
  padding-bottom: 4px;
}
.pr4 {
  padding-right: 4px;
}
.pl4 {
  padding-left: 4px;
}
.pt4 {
  padding-top: 4px;
}
.mb6 {
  margin-bottom: 6px;
}
.mr6 {
  margin-right: 6px;
}
.ml6 {
  margin-left: 6px;
}
.mt6 {
  margin-top: 6px;
}
.mh6 {
  margin-right: 6px;
  margin-left: 6px;
}
.mv6 {
  margin-top: 6px;
  margin-bottom: 6px;
}
.pb6 {
  padding-bottom: 6px;
}
.pr6 {
  padding-right: 6px;
}
.pl6 {
  padding-left: 6px;
}
.pt6 {
  padding-top: 6px;
}
.mb8 {
  margin-bottom: 8px;
}
.mr8 {
  margin-right: 8px;
}
.ml8 {
  margin-left: 8px;
}
.mt8 {
  margin-top: 8px;
}
.mh8 {
  margin-right: 8px;
  margin-left: 8px;
}
.mv8 {
  margin-top: 8px;
  margin-bottom: 8px;
}
.pb8 {
  padding-bottom: 8px;
}
.pr8 {
  padding-right: 8px;
}
.pl8 {
  padding-left: 8px;
}
.pt8 {
  padding-top: 8px;
}
.mb10 {
  margin-bottom: 10px;
}
.mr10 {
  margin-right: 10px;
}
.ml10 {
  margin-left: 10px;
}
.mt10 {
  margin-top: 10px;
}
.mh10 {
  margin-right: 10px;
  margin-left: 10px;
}
.mv10 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.pb10 {
  padding-bottom: 10px;
}
.pr10 {
  padding-right: 10px;
}
.pl10 {
  padding-left: 10px;
}
.pt10 {
  padding-top: 10px;
}
.mb12 {
  margin-bottom: 12px;
}
.mr12 {
  margin-right: 12px;
}
.ml12 {
  margin-left: 12px;
}
.mt12 {
  margin-top: 12px;
}
.mh12 {
  margin-right: 12px;
  margin-left: 12px;
}
.mv12 {
  margin-top: 12px;
  margin-bottom: 12px;
}
.pb12 {
  padding-bottom: 12px;
}
.pr12 {
  padding-right: 12px;
}
.pl12 {
  padding-left: 12px;
}
.pt12 {
  padding-top: 12px;
}
.mb14 {
  margin-bottom: 14px;
}
.mr14 {
  margin-right: 14px;
}
.ml14 {
  margin-left: 14px;
}
.mt14 {
  margin-top: 14px;
}
.mh14 {
  margin-right: 14px;
  margin-left: 14px;
}
.mv14 {
  margin-top: 14px;
  margin-bottom: 14px;
}
.pb14 {
  padding-bottom: 14px;
}
.pr14 {
  padding-right: 14px;
}
.pl14 {
  padding-left: 14px;
}
.pt14 {
  padding-top: 14px;
}
.mb16 {
  margin-bottom: 16px;
}
.mr16 {
  margin-right: 16px;
}
.ml16 {
  margin-left: 16px;
}
.mt16 {
  margin-top: 16px;
}
.mh16 {
  margin-right: 16px;
  margin-left: 16px;
}
.mv16 {
  margin-top: 16px;
  margin-bottom: 16px;
}
.pb16 {
  padding-bottom: 16px;
}
.pr16 {
  padding-right: 16px;
}
.pl16 {
  padding-left: 16px;
}
.pt16 {
  padding-top: 16px;
}
.mb18 {
  margin-bottom: 18px;
}
.mr18 {
  margin-right: 18px;
}
.ml18 {
  margin-left: 18px;
}
.mt18 {
  margin-top: 18px;
}
.mh18 {
  margin-right: 18px;
  margin-left: 18px;
}
.mv18 {
  margin-top: 18px;
  margin-bottom: 18px;
}
.pb18 {
  padding-bottom: 18px;
}
.pr18 {
  padding-right: 18px;
}
.pl18 {
  padding-left: 18px;
}
.pt18 {
  padding-top: 18px;
}
.mb20 {
  margin-bottom: 20px;
}
.mr20 {
  margin-right: 20px;
}
.ml20 {
  margin-left: 20px;
}
.mt20 {
  margin-top: 20px;
}
.mh20 {
  margin-right: 20px;
  margin-left: 20px;
}
.mv20 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.pb20 {
  padding-bottom: 20px;
}
.pr20 {
  padding-right: 20px;
}
.pl20 {
  padding-left: 20px;
}
.pt20 {
  padding-top: 20px;
}
.mb22 {
  margin-bottom: 22px;
}
.mr22 {
  margin-right: 22px;
}
.ml22 {
  margin-left: 22px;
}
.mt22 {
  margin-top: 22px;
}
.mh22 {
  margin-right: 22px;
  margin-left: 22px;
}
.mv22 {
  margin-top: 22px;
  margin-bottom: 22px;
}
.pb22 {
  padding-bottom: 22px;
}
.pr22 {
  padding-right: 22px;
}
.pl22 {
  padding-left: 22px;
}
.pt22 {
  padding-top: 22px;
}
.mb24 {
  margin-bottom: 24px;
}
.mr24 {
  margin-right: 24px;
}
.ml24 {
  margin-left: 24px;
}
.mt24 {
  margin-top: 24px;
}
.mh24 {
  margin-right: 24px;
  margin-left: 24px;
}
.mv24 {
  margin-top: 24px;
  margin-bottom: 24px;
}
.pb24 {
  padding-bottom: 24px;
}
.pr24 {
  padding-right: 24px;
}
.pl24 {
  padding-left: 24px;
}
.pt24 {
  padding-top: 24px;
}
.mb26 {
  margin-bottom: 26px;
}
.mr26 {
  margin-right: 26px;
}
.ml26 {
  margin-left: 26px;
}
.mt26 {
  margin-top: 26px;
}
.mh26 {
  margin-right: 26px;
  margin-left: 26px;
}
.mv26 {
  margin-top: 26px;
  margin-bottom: 26px;
}
.pb26 {
  padding-bottom: 26px;
}
.pr26 {
  padding-right: 26px;
}
.pl26 {
  padding-left: 26px;
}
.pt26 {
  padding-top: 26px;
}
.mb28 {
  margin-bottom: 28px;
}
.mr28 {
  margin-right: 28px;
}
.ml28 {
  margin-left: 28px;
}
.mt28 {
  margin-top: 28px;
}
.mh28 {
  margin-right: 28px;
  margin-left: 28px;
}
.mv28 {
  margin-top: 28px;
  margin-bottom: 28px;
}
.pb28 {
  padding-bottom: 28px;
}
.pr28 {
  padding-right: 28px;
}
.pl28 {
  padding-left: 28px;
}
.pt28 {
  padding-top: 28px;
}
.mb30 {
  margin-bottom: 30px;
}
.mr30 {
  margin-right: 30px;
}
.ml30 {
  margin-left: 30px;
}
.mt30 {
  margin-top: 30px;
}
.mh30 {
  margin-right: 30px;
  margin-left: 30px;
}
.mv30 {
  margin-top: 30px;
  margin-bottom: 30px;
}
.pb30 {
  padding-bottom: 30px;
}
.pr30 {
  padding-right: 30px;
}
.pl30 {
  padding-left: 30px;
}
.pt30 {
  padding-top: 30px;
}
.mb32 {
  margin-bottom: 32px;
}
.mr32 {
  margin-right: 32px;
}
.ml32 {
  margin-left: 32px;
}
.mt32 {
  margin-top: 32px;
}
.mh32 {
  margin-right: 32px;
  margin-left: 32px;
}
.mv32 {
  margin-top: 32px;
  margin-bottom: 32px;
}
.pb32 {
  padding-bottom: 32px;
}
.pr32 {
  padding-right: 32px;
}
.pl32 {
  padding-left: 32px;
}
.pt32 {
  padding-top: 32px;
}
.mb34 {
  margin-bottom: 34px;
}
.mr34 {
  margin-right: 34px;
}
.ml34 {
  margin-left: 34px;
}
.mt34 {
  margin-top: 34px;
}
.mh34 {
  margin-right: 34px;
  margin-left: 34px;
}
.mv34 {
  margin-top: 34px;
  margin-bottom: 34px;
}
.pb34 {
  padding-bottom: 34px;
}
.pr34 {
  padding-right: 34px;
}
.pl34 {
  padding-left: 34px;
}
.pt34 {
  padding-top: 34px;
}
.mb36 {
  margin-bottom: 36px;
}
.mr36 {
  margin-right: 36px;
}
.ml36 {
  margin-left: 36px;
}
.mt36 {
  margin-top: 36px;
}
.mh36 {
  margin-right: 36px;
  margin-left: 36px;
}
.mv36 {
  margin-top: 36px;
  margin-bottom: 36px;
}
.pb36 {
  padding-bottom: 36px;
}
.pr36 {
  padding-right: 36px;
}
.pl36 {
  padding-left: 36px;
}
.pt36 {
  padding-top: 36px;
}
.mb38 {
  margin-bottom: 38px;
}
.mr38 {
  margin-right: 38px;
}
.ml38 {
  margin-left: 38px;
}
.mt38 {
  margin-top: 38px;
}
.mh38 {
  margin-right: 38px;
  margin-left: 38px;
}
.mv38 {
  margin-top: 38px;
  margin-bottom: 38px;
}
.pb38 {
  padding-bottom: 38px;
}
.pr38 {
  padding-right: 38px;
}
.pl38 {
  padding-left: 38px;
}
.pt38 {
  padding-top: 38px;
}
.mb40 {
  margin-bottom: 40px;
}
.mr40 {
  margin-right: 40px;
}
.ml40 {
  margin-left: 40px;
}
.mt40 {
  margin-top: 40px;
}
.mh40 {
  margin-right: 40px;
  margin-left: 40px;
}
.mv40 {
  margin-top: 40px;
  margin-bottom: 40px;
}
.pb40 {
  padding-bottom: 40px;
}
.pr40 {
  padding-right: 40px;
}
.pl40 {
  padding-left: 40px;
}
.pt40 {
  padding-top: 40px;
}
.mb42 {
  margin-bottom: 42px;
}
.mr42 {
  margin-right: 42px;
}
.ml42 {
  margin-left: 42px;
}
.mt42 {
  margin-top: 42px;
}
.mh42 {
  margin-right: 42px;
  margin-left: 42px;
}
.mv42 {
  margin-top: 42px;
  margin-bottom: 42px;
}
.pb42 {
  padding-bottom: 42px;
}
.pr42 {
  padding-right: 42px;
}
.pl42 {
  padding-left: 42px;
}
.pt42 {
  padding-top: 42px;
}
.mb44 {
  margin-bottom: 44px;
}
.mr44 {
  margin-right: 44px;
}
.ml44 {
  margin-left: 44px;
}
.mt44 {
  margin-top: 44px;
}
.mh44 {
  margin-right: 44px;
  margin-left: 44px;
}
.mv44 {
  margin-top: 44px;
  margin-bottom: 44px;
}
.pb44 {
  padding-bottom: 44px;
}
.pr44 {
  padding-right: 44px;
}
.pl44 {
  padding-left: 44px;
}
.pt44 {
  padding-top: 44px;
}
.mb46 {
  margin-bottom: 46px;
}
.mr46 {
  margin-right: 46px;
}
.ml46 {
  margin-left: 46px;
}
.mt46 {
  margin-top: 46px;
}
.mh46 {
  margin-right: 46px;
  margin-left: 46px;
}
.mv46 {
  margin-top: 46px;
  margin-bottom: 46px;
}
.pb46 {
  padding-bottom: 46px;
}
.pr46 {
  padding-right: 46px;
}
.pl46 {
  padding-left: 46px;
}
.pt46 {
  padding-top: 46px;
}
.mb48 {
  margin-bottom: 48px;
}
.mr48 {
  margin-right: 48px;
}
.ml48 {
  margin-left: 48px;
}
.mt48 {
  margin-top: 48px;
}
.mh48 {
  margin-right: 48px;
  margin-left: 48px;
}
.mv48 {
  margin-top: 48px;
  margin-bottom: 48px;
}
.pb48 {
  padding-bottom: 48px;
}
.pr48 {
  padding-right: 48px;
}
.pl48 {
  padding-left: 48px;
}
.pt48 {
  padding-top: 48px;
}
.mb50 {
  margin-bottom: 50px;
}
.mr50 {
  margin-right: 50px;
}
.ml50 {
  margin-left: 50px;
}
.mt50 {
  margin-top: 50px;
}
.mh50 {
  margin-right: 50px;
  margin-left: 50px;
}
.mv50 {
  margin-top: 50px;
  margin-bottom: 50px;
}
.pb50 {
  padding-bottom: 50px;
}
.pr50 {
  padding-right: 50px;
}
.pl50 {
  padding-left: 50px;
}
.pt50 {
  padding-top: 50px;
}
.mb52 {
  margin-bottom: 52px;
}
.mr52 {
  margin-right: 52px;
}
.ml52 {
  margin-left: 52px;
}
.mt52 {
  margin-top: 52px;
}
.mh52 {
  margin-right: 52px;
  margin-left: 52px;
}
.mv52 {
  margin-top: 52px;
  margin-bottom: 52px;
}
.pb52 {
  padding-bottom: 52px;
}
.pr52 {
  padding-right: 52px;
}
.pl52 {
  padding-left: 52px;
}
.pt52 {
  padding-top: 52px;
}
.mb54 {
  margin-bottom: 54px;
}
.mr54 {
  margin-right: 54px;
}
.ml54 {
  margin-left: 54px;
}
.mt54 {
  margin-top: 54px;
}
.mh54 {
  margin-right: 54px;
  margin-left: 54px;
}
.mv54 {
  margin-top: 54px;
  margin-bottom: 54px;
}
.pb54 {
  padding-bottom: 54px;
}
.pr54 {
  padding-right: 54px;
}
.pl54 {
  padding-left: 54px;
}
.pt54 {
  padding-top: 54px;
}
.mb56 {
  margin-bottom: 56px;
}
.mr56 {
  margin-right: 56px;
}
.ml56 {
  margin-left: 56px;
}
.mt56 {
  margin-top: 56px;
}
.mh56 {
  margin-right: 56px;
  margin-left: 56px;
}
.mv56 {
  margin-top: 56px;
  margin-bottom: 56px;
}
.pb56 {
  padding-bottom: 56px;
}
.pr56 {
  padding-right: 56px;
}
.pl56 {
  padding-left: 56px;
}
.pt56 {
  padding-top: 56px;
}
.mb58 {
  margin-bottom: 58px;
}
.mr58 {
  margin-right: 58px;
}
.ml58 {
  margin-left: 58px;
}
.mt58 {
  margin-top: 58px;
}
.mh58 {
  margin-right: 58px;
  margin-left: 58px;
}
.mv58 {
  margin-top: 58px;
  margin-bottom: 58px;
}
.pb58 {
  padding-bottom: 58px;
}
.pr58 {
  padding-right: 58px;
}
.pl58 {
  padding-left: 58px;
}
.pt58 {
  padding-top: 58px;
}
.mb60 {
  margin-bottom: 60px;
}
.mr60 {
  margin-right: 60px;
}
.ml60 {
  margin-left: 60px;
}
.mt60 {
  margin-top: 60px;
}
.mh60 {
  margin-right: 60px;
  margin-left: 60px;
}
.mv60 {
  margin-top: 60px;
  margin-bottom: 60px;
}
.pb60 {
  padding-bottom: 60px;
}
.pr60 {
  padding-right: 60px;
}
.pl60 {
  padding-left: 60px;
}
.pt60 {
  padding-top: 60px;
}
.mb62 {
  margin-bottom: 62px;
}
.mr62 {
  margin-right: 62px;
}
.ml62 {
  margin-left: 62px;
}
.mt62 {
  margin-top: 62px;
}
.mh62 {
  margin-right: 62px;
  margin-left: 62px;
}
.mv62 {
  margin-top: 62px;
  margin-bottom: 62px;
}
.pb62 {
  padding-bottom: 62px;
}
.pr62 {
  padding-right: 62px;
}
.pl62 {
  padding-left: 62px;
}
.pt62 {
  padding-top: 62px;
}
.mb64 {
  margin-bottom: 64px;
}
.mr64 {
  margin-right: 64px;
}
.ml64 {
  margin-left: 64px;
}
.mt64 {
  margin-top: 64px;
}
.mh64 {
  margin-right: 64px;
  margin-left: 64px;
}
.mv64 {
  margin-top: 64px;
  margin-bottom: 64px;
}
.pb64 {
  padding-bottom: 64px;
}
.pr64 {
  padding-right: 64px;
}
.pl64 {
  padding-left: 64px;
}
.pt64 {
  padding-top: 64px;
}
.f1 {
  flex: 1;
}
.f2 {
  flex: 2;
}
.f3 {
  flex: 3;
}
.f4 {
  flex: 4;
}
.f5 {
  flex: 5;
}
.f6 {
  flex: 6;
}
.f7 {
  flex: 7;
}
.f8 {
  flex: 8;
}
.f9 {
  flex: 9;
}
.f10 {
  flex: 10;
}
.f11 {
  flex: 11;
}
.f12 {
  flex: 12;
}
.d-flex {
  display: flex;
}
.flex-r {
  display: flex;
  flex-direction: row;
}
.flex-rr {
  display: flex;
  flex-direction: row-reverse;
}
.flex-c {
  display: flex;
  flex-direction: column;
}
.flex-cr {
  display: flex;
  flex-direction: column-reverse;
}
.flex-cr {
  display: flex;
  flex-direction: column-reverse;
}
.frc {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.fcc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.full-width {
  width: 100%;
}
.d-grid {
  display: grid;
}
.d-none {
  display: none;
}
.d-block {
  display: block;
}
.d-table {
  display: table;
}
.width-wrapper {
  max-width: 100%;
  overflow-x: hidden;
}
.overflow-hidden {
  overflow: hidden;
}
.align-center {
  align-items: center;
}
.align-right {
  align-items: flex-end;
}
.align-left {
  align-items: flex-start;
}
.justify-center {
  justify-content: center;
}
.justify-right {
  justify-content: flex-end;
}
.justify-left {
  justify-content: flex-start;
}
.justify-between {
  justify-content: space-between !important;
}
.justify-around {
  justify-content: space-around;
}
.justify-evenly {
  justify-content: space-evenly;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.z0 {
  z-index: 0;
}
.z1 {
  z-index: 1;
}
.z-1 {
  z-index: -1;
}
.z2 {
  z-index: 2;
}
.z-2 {
  z-index: -2;
}
.z3 {
  z-index: 3;
}
.z-3 {
  z-index: -3;
}
.z4 {
  z-index: 4;
}
.z-4 {
  z-index: -4;
}
.z5 {
  z-index: 5;
}
.z-5 {
  z-index: -5;
}
.z6 {
  z-index: 6;
}
.z-6 {
  z-index: -6;
}
.z7 {
  z-index: 7;
}
.z-7 {
  z-index: -7;
}
.z8 {
  z-index: 8;
}
.z-8 {
  z-index: -8;
}
.z9 {
  z-index: 9;
}
.z-9 {
  z-index: -9;
}
.z10 {
  z-index: 10;
}
.z-10 {
  z-index: -10;
}
.g0 {
  gap: 0px;
}
.g2 {
  gap: 2px;
}
.g4 {
  gap: 4px;
}
.g6 {
  gap: 6px;
}
.g8 {
  gap: 8px;
}
.g10 {
  gap: 10px;
}
.g12 {
  gap: 12px;
}
.g14 {
  gap: 14px;
}
.g16 {
  gap: 16px;
}
.block-width-25 {
  width: 25%;
}
.block-width-26 {
  width: 26%;
}
.block-width-27 {
  width: 27%;
}
.block-width-28 {
  width: 28%;
}
.block-width-29 {
  width: 29%;
}
.block-width-30 {
  width: 30%;
}
.block-width-31 {
  width: 31%;
}
.block-width-32 {
  width: 32%;
}
.block-width-33 {
  width: 33%;
}
.block-width-34 {
  width: 34%;
}
.block-width-35 {
  width: 35%;
}
.block-width-36 {
  width: 36%;
}
.block-width-37 {
  width: 37%;
}
.block-width-38 {
  width: 38%;
}
.block-width-39 {
  width: 39%;
}
.block-width-40 {
  width: 40%;
}
.block-width-41 {
  width: 41%;
}
.block-width-42 {
  width: 42%;
}
.block-width-43 {
  width: 43%;
}
.block-width-44 {
  width: 44%;
}
.block-width-45 {
  width: 45%;
}
.block-width-46 {
  width: 46%;
}
.block-width-47 {
  width: 47%;
}
.block-width-48 {
  width: 48%;
}
.block-width-49 {
  width: 49%;
}
.block-width-50 {
  width: 50%;
}
.block-width-51 {
  width: 51%;
}
.block-width-52 {
  width: 52%;
}
.block-width-53 {
  width: 53%;
}
.block-width-54 {
  width: 54%;
}
.block-width-55 {
  width: 55%;
}
.block-width-56 {
  width: 56%;
}
.block-width-57 {
  width: 57%;
}
.block-width-58 {
  width: 58%;
}
.block-width-59 {
  width: 59%;
}
.block-width-60 {
  width: 60%;
}
.block-width-61 {
  width: 61%;
}
.block-width-62 {
  width: 62%;
}
.block-width-63 {
  width: 63%;
}
.block-width-64 {
  width: 64%;
}
.block-width-65 {
  width: 65%;
}
.block-width-66 {
  width: 66%;
}
.block-width-67 {
  width: 67%;
}
.block-width-68 {
  width: 68%;
}
.block-width-69 {
  width: 69%;
}
.block-width-70 {
  width: 70%;
}
.block-width-71 {
  width: 71%;
}
.block-width-72 {
  width: 72%;
}
.block-width-73 {
  width: 73%;
}
.block-width-74 {
  width: 74%;
}
.block-width-75 {
  width: 75%;
}
.bgg1 {
  background: var(--g1);
}
.bgg2 {
  background: var(--g2);
}
.cc1 {
  color: var(--c1);
}
.bgc1 {
  background-color: var(--c1);
}
.shadow-c1 {
  box-shadow: 0px 20px 16px -10px var(--sc1);
}
.border-c1 {
  border-color: var(--c1);
}
.cc2 {
  color: var(--c2);
}
.bgc2 {
  background-color: var(--c2);
}
.shadow-c2 {
  box-shadow: 0px 20px 16px -10px var(--sc2);
}
.border-c2 {
  border-color: var(--c2);
}
.cc3 {
  color: var(--c3);
}
.bgc3 {
  background-color: var(--c3);
}
.shadow-c3 {
  box-shadow: 0px 20px 16px -10px var(--sc3);
}
.border-c3 {
  border-color: var(--c3);
}
.cc4 {
  color: var(--c4);
}
.bgc4 {
  background-color: var(--c4);
}
.shadow-c4 {
  box-shadow: 0px 20px 16px -10px var(--sc4);
}
.border-c4 {
  border-color: var(--c4);
}
.cc5 {
  color: var(--c5);
}
.bgc5 {
  background-color: var(--c5);
}
.shadow-c5 {
  box-shadow: 0px 20px 16px -10px var(--sc5);
}
.border-c5 {
  border-color: var(--c5);
}
.cc6 {
  color: var(--c6);
}
.bgc6 {
  background-color: var(--c6);
}
.shadow-c6 {
  box-shadow: 0px 20px 16px -10px var(--sc6);
}
.border-c6 {
  border-color: var(--c6);
}
.cc7 {
  color: var(--c7);
}
.bgc7 {
  background-color: var(--c7);
}
.shadow-c7 {
  box-shadow: 0px 20px 16px -10px var(--sc7);
}
.border-c7 {
  border-color: var(--c7);
}
.cc8 {
  color: var(--c8);
}
.bgc8 {
  background-color: var(--c8);
}
.shadow-c8 {
  box-shadow: 0px 20px 16px -10px var(--sc8);
}
.border-c8 {
  border-color: var(--c8);
}
.cc9 {
  color: var(--c9);
}
.bgc9 {
  background-color: var(--c9);
}
.shadow-c9 {
  box-shadow: 0px 20px 16px -10px var(--sc9);
}
.border-c9 {
  border-color: var(--c9);
}
.cc10 {
  color: var(--c10);
}
.bgc10 {
  background-color: var(--c10);
}
.shadow-c10 {
  box-shadow: 0px 20px 16px -10px var(--sc10);
}
.border-c10 {
  border-color: var(--c10);
}
.cc11 {
  color: var(--c11);
}
.bgc11 {
  background-color: var(--c11);
}
.shadow-c11 {
  box-shadow: 0px 20px 16px -10px var(--sc11);
}
.border-c11 {
  border-color: var(--c11);
}
.cc12 {
  color: var(--c12);
}
.bgc12 {
  background-color: var(--c12);
}
.shadow-c12 {
  box-shadow: 0px 20px 16px -10px var(--sc12);
}
.border-c12 {
  border-color: var(--c12);
}
.cc13 {
  color: var(--c13);
}
.bgc13 {
  background-color: var(--c13);
}
.shadow-c13 {
  box-shadow: 0px 20px 16px -10px var(--sc13);
}
.border-c13 {
  border-color: var(--c13);
}
.cc14 {
  color: var(--c14);
}
.bgc14 {
  background-color: var(--c14);
}
.shadow-c14 {
  box-shadow: 0px 20px 16px -10px var(--sc14);
}
.border-c14 {
  border-color: var(--c14);
}
.cc15 {
  color: var(--c15);
}
.bgc15 {
  background-color: var(--c15);
}
.shadow-c15 {
  box-shadow: 0px 20px 16px -10px var(--sc15);
}
.border-c15 {
  border-color: var(--c15);
}
.cb {
  color: var(--b);
}
.bgb {
  background-color: var(--b);
}
.shadow-b {
  box-shadow: 0px 20px 16px -10px var(--sb);
}
.border-b {
  border-color: var(--b);
}
.cw {
  color: var(--w);
}
.bgw {
  background-color: var(--w);
}
.shadow-w {
  box-shadow: 0px 20px 16px -10px var(--sw);
}
.border-w {
  border-color: var(--w);
}
.anim-delay-0 {
  animation-delay: 0s !important;
}
.anim-delay-1 {
  animation-delay: 1s !important;
}
.anim-delay-2 {
  animation-delay: 2s !important;
}
.anim-delay-3 {
  animation-delay: 3s !important;
}
.anim-delay-4 {
  animation-delay: 4s !important;
}
.anim-delay-5 {
  animation-delay: 5s !important;
}
.anim-delay-6 {
  animation-delay: 6s !important;
}
.anim-delay-7 {
  animation-delay: 7s !important;
}
.anim-delay-8 {
  animation-delay: 8s !important;
}
.anim-delay-9 {
  animation-delay: 9s !important;
}
.trans-delay-0 {
  transition-delay: 0s;
}
.trans-delay-1 {
  transition-delay: 1s;
}
.trans-delay-2 {
  transition-delay: 2s;
}
.trans-delay-3 {
  transition-delay: 3s;
}
.trans-delay-4 {
  transition-delay: 4s;
}
.trans-delay-5 {
  transition-delay: 5s;
}
.trans-delay-6 {
  transition-delay: 6s;
}
.trans-delay-7 {
  transition-delay: 7s;
}
.trans-delay-8 {
  transition-delay: 8s;
}
.trans-delay-9 {
  transition-delay: 9s;
}
.block-height-25 {
  height: 25%;
}
.block-height-26 {
  height: 26%;
}
.block-height-27 {
  height: 27%;
}
.block-height-28 {
  height: 28%;
}
.block-height-29 {
  height: 29%;
}
.block-height-30 {
  height: 30%;
}
.block-height-31 {
  height: 31%;
}
.block-height-32 {
  height: 32%;
}
.block-height-33 {
  height: 33%;
}
.block-height-34 {
  height: 34%;
}
.block-height-35 {
  height: 35%;
}
.block-height-36 {
  height: 36%;
}
.block-height-37 {
  height: 37%;
}
.block-height-38 {
  height: 38%;
}
.block-height-39 {
  height: 39%;
}
.block-height-40 {
  height: 40%;
}
.block-height-41 {
  height: 41%;
}
.block-height-42 {
  height: 42%;
}
.block-height-43 {
  height: 43%;
}
.block-height-44 {
  height: 44%;
}
.block-height-45 {
  height: 45%;
}
.block-height-46 {
  height: 46%;
}
.block-height-47 {
  height: 47%;
}
.block-height-48 {
  height: 48%;
}
.block-height-49 {
  height: 49%;
}
.block-height-50 {
  height: 50%;
}
.block-height-51 {
  height: 51%;
}
.block-height-52 {
  height: 52%;
}
.block-height-53 {
  height: 53%;
}
.block-height-54 {
  height: 54%;
}
.block-height-55 {
  height: 55%;
}
.block-height-56 {
  height: 56%;
}
.block-height-57 {
  height: 57%;
}
.block-height-58 {
  height: 58%;
}
.block-height-59 {
  height: 59%;
}
.block-height-60 {
  height: 60%;
}
.block-height-61 {
  height: 61%;
}
.block-height-62 {
  height: 62%;
}
.block-height-63 {
  height: 63%;
}
.block-height-64 {
  height: 64%;
}
.block-height-65 {
  height: 65%;
}
.block-height-66 {
  height: 66%;
}
.block-height-67 {
  height: 67%;
}
.block-height-68 {
  height: 68%;
}
.block-height-69 {
  height: 69%;
}
.block-height-70 {
  height: 70%;
}
.block-height-71 {
  height: 71%;
}
.block-height-72 {
  height: 72%;
}
.block-height-73 {
  height: 73%;
}
.block-height-74 {
  height: 74%;
}
.block-height-75 {
  height: 75%;
}
.lh70 {
  line-height: 70%;
}
.lh75 {
  line-height: 75%;
}
.lh80 {
  line-height: 80%;
}
.lh85 {
  line-height: 85%;
}
.lh90 {
  line-height: 90%;
}
.lh95 {
  line-height: 95%;
}
.lh100 {
  line-height: 100%;
}
.lh105 {
  line-height: 105%;
}
.lh110 {
  line-height: 110%;
}
.lh115 {
  line-height: 115%;
}
.lh120 {
  line-height: 120%;
}
.lh125 {
  line-height: 125%;
}
.lh130 {
  line-height: 130%;
}
.lh135 {
  line-height: 135%;
}
.lh140 {
  line-height: 140%;
}
.lh145 {
  line-height: 145%;
}
.lh150 {
  line-height: 150%;
}
.lh155 {
  line-height: 155%;
}
.lh160 {
  line-height: 160%;
}
.lh165 {
  line-height: 165%;
}
.lh170 {
  line-height: 170%;
}
.lh175 {
  line-height: 175%;
}
.lh180 {
  line-height: 180%;
}
.lh185 {
  line-height: 185%;
}
.lh190 {
  line-height: 190%;
}
.lh195 {
  line-height: 195%;
}
.lh200 {
  line-height: 200%;
}
.force-full-width {
  width: 100% !important;
  max-width: unset !important;
  min-width: unset !important;
}
.flex-wrap {
  flex-wrap: wrap;
}
.p-rel {
  position: relative;
}
.cant-touch {
  pointer-events: none;
  user-select: none;
}
.cant-select {
  user-select: none;
}
.pointy {
  cursor: pointer;
}
.hidden {
  display: none !important;
}
.ellipsis {
  text-overflow: ellipsis;
}
.mw-30vw {
  min-width: 30vw;
}
.mw-40vw {
  min-width: 40vw;
}
.mw-50vw {
  min-width: 50vw;
}
.mw-60vw {
  min-width: 60vw;
}
.break-words {
  word-break: break-all;
}
.width-content {
  width: max-content;
}
.dont-break {
  word-break: keep-all;
  width: max-content;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
input,
textarea,
span,
label,
a {
  font-family: "PT-Mono";
  line-height: 100%;
}
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.card {
  border-radius: 32px;
  padding: 24px 32px;
}
.input-wrapper {
  .input-border {
    padding: 5px;
    border: solid 1px transparent;
    border-radius: 20px;
    &:hover {
      border: solid 1px #fff;
    }
    &:focus-within {
      border: solid 1px #fff;
    }
    input,
    textarea {
      width: 220px;
      border-radius: 16px;
      border: none;
      font-size: 16px;
      padding: 16px;
      line-height: 150%;
      resize: none;
      &:focus {
        &::placeholder {
          color: var(--c11);
        }
        &::-webkit-input-placeholder {
          color: var(--c11);
        }
        &::-moz-placeholder {
          color: var(--c11);
        }
        &::-ms-input-placeholder {
          color: var(--c11);
        }
        &:-ms-input-placeholder {
          color: var(--c11);
        }
      }
    }
  }
  &.error {
    input,
    textarea {
      color: var(--c11);
      background: linear-gradient(0deg, rgba(212, 72, 72, 0.2), rgba(212, 72, 72, 0.2)), #fff;
      &::placeholder {
        color: var(--error);
      }
      &::-webkit-input-placeholder {
        color: var(--error);
      }
      &::-moz-placeholder {
        color: var(--error);
      }
      &::-ms-input-placeholder {
        color: var(--error);
      }
      &:-ms-input-placeholder {
        color: var(--error);
      }
    }
  }
}
.button {
  gap: 12px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  width: max-content;
  &.drop-shadow {
    box-shadow: 0 10px 16px rgba(10, 33, 41, 0.25);
  }
  &.compact {
    padding: 8px 16px;
  }
  &.full-width {
    width: 100%;
  }
}
.button,
.link-button {
  border-radius: 16px;
  &.compact {
    border-radius: 8px;
  }
  &.shadow-hover-sw:hover {
    box-shadow: 0 10px 16px -10px var(--sw);
  }
  &.shadow-hover-sc1:hover {
    box-shadow: 0 10px 16px -10px var(--sc1);
  }
  &.shadow-hover-sc2:hover {
    box-shadow: 0 10px 16px -10px var(--sc2);
  }
  &.shadow-hover-sc3:hover {
    box-shadow: 0 10px 16px -10px var(--sc3);
  }
  &.shadow-hover-sc4:hover {
    box-shadow: 0 10px 16px -10px var(--sc4);
  }
  &.shadow-hover-sc5:hover {
    box-shadow: 0 10px 16px -10px var(--sc5);
  }
  &.shadow-hover-sc6:hover {
    box-shadow: 0 10px 16px -10px var(--sc6);
  }
  &.shadow-hover-sc7:hover {
    box-shadow: 0 10px 16px -10px var(--sc7);
  }
  &.shadow-hover-sc8:hover {
    box-shadow: 0 10px 16px -10px var(--sc8);
  }
  &.shadow-hover-sc9:hover {
    box-shadow: 0 10px 16px -10px var(--sc9);
  }
  &.shadow-hover-sc10:hover {
    box-shadow: 0 10px 16px -10px var(--sc10);
  }
  &.shadow-hover-sc11:hover {
    box-shadow: 0 10px 16px -10px var(--sc11);
  }
  &.shadow-hover-sc12:hover {
    box-shadow: 0 10px 16px -10px var(--sc12);
  }
  &.shadow-hover-sc13:hover {
    box-shadow: 0 10px 16px -10px var(--sc13);
  }
  &.shadow-hover-sc14:hover {
    box-shadow: 0 10px 16px -10px var(--sc14);
  }
  &.shadow-hover-sc15:hover {
    box-shadow: 0 10px 16px -10px var(--sc15);
  }
}
.icon-button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  &.drop-shadow {
    box-shadow: 0 10px 16px rgba(10, 33, 41, 0.25);
  }
}
.triangle {
  background-image: url("data:image/svg+xml,%3Csvg width='766' height='766' viewBox='0 0 766 766' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M366.369 28.6959C373.682 16.0292 391.965 16.0291 399.278 28.6958L697.734 545.638C705.047 558.304 695.906 574.138 681.28 574.138L84.3668 574.138C69.7406 574.138 60.5991 558.304 67.9122 545.638L366.369 28.6959Z' fill='white' fill-opacity='0.1'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center top;
  width: 765px;
  height: 765px;
}
.white-2 {
  background-color: white;
  opacity: 0.1;
}
.rectangle-212 {
  width: 212px;
  height: 212px;
}
.rectangle-72 {
  width: 72px;
  height: 72px;
}
.round {
  width: 160px;
  height: 160px;
  border-radius: 50%;
}
@media screen and (min-width: 960px) and (-webkit-device-pixel-ratio: 1.25) {
  section,
  header,
  footer,
  div.review-modal {
    zoom: 0.8;
  }
}
