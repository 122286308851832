section.about {
  width: 100vw;
  .double-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    .double-col {
      .animated-content {
        height: 100%;
        position: relative;
        svg {
          position: absolute;
          bottom: -150px;
          left: -300px;
          height: unset;
          width: unset;
          transform: rotateZ(-15deg);
          transform-origin: center bottom;
          transition: all 1s ease-in-out;
          z-index: 1;
        }
        &.animate svg {
          bottom: -50px;
          left: -30px;
          transform: rotateZ(15deg);
          transition: all 1s ease-in-out;
        }
        .rectangle-212 {
          position: absolute;
          top: 100px;
          left: 180px;
        }
        .rectangle-72 {
          position: absolute;
          top: 216px;
          left: 490px;
        }
      }
      .double-box {
        width: 480px;
        margin: 150px 0 100px 50px;
      }
    }
  }
}

@media screen and (-webkit-device-pixel-ratio: 1.25){
  section.about{
    width: 125vw;
  }
}


section.m-about{
  padding: 40px 16px;
}