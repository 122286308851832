section.course-program {
  width: 100vw;
  padding: 120px;
  box-sizing: border-box;
  min-height: 600px;
  @media screen and (-webkit-device-pixel-ratio: 1.25) {
    width: 125vw;
  }
  .details {
    display: flex;
    column-gap: 32px;
    .modules {
      .module {
        width: 550px;
        padding: 20px 24px 16px;
        margin-bottom: 18px;
        border-radius: 16px;
        position: relative;
        .title {
          padding-right: 64px;
        }
        .extra-info {
          display: flex;
          column-gap: 16px;
        }
        .expand-button {
          position: absolute;
          right: 24px;
          top: 16px;
          height: 32px;
          width: 32px;
          border-radius: 4px;
          cursor: pointer;
          background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='32' height='32' rx='4' fill='%23F5F5F5'/%3E%3Cpath d='M11 15.5H21V17.5H11V15.5Z' fill='%234D4D4D'/%3E%3Crect x='17' y='11.5' width='10' height='2' transform='rotate(90 17 11.5)' fill='%234D4D4D'/%3E%3C/svg%3E%0A");
          background-position: center;
          background-repeat: no-repeat;
          transition: 300ms ease;
          &:hover {
            background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='32' height='32' rx='4' fill='%234D4D4D'/%3E%3Cpath d='M11 15.5H21V17.5H11V15.5Z' fill='%23F5F5F5'/%3E%3Crect x='17' y='11.5' width='10' height='2' transform='rotate(90 17 11.5)' fill='%23F5F5F5'/%3E%3C/svg%3E%0A");
          }
        }
        &.selected {
          filter: drop-shadow(-1px -1px 0 var(--c1));
          .expand-button {
            background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='32' height='32' rx='4' fill='%23F5F5F5'/%3E%3Cpath d='M11 15.5H21V17.5H11V15.5Z' fill='%234D4D4D'/%3E%3C/svg%3E%0A");
            &:hover {
              background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='32' height='32' rx='4' fill='%234D4D4D'/%3E%3Crect x='11' y='15.5' width='10' height='2' fill='%23F5F5F5'/%3E%3C/svg%3E%0A");
            }
          }
        }
      }
    }
    .module-description {
      width: 1095px;
      height: fit-content;
      padding: 88px 32px 40px;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      row-gap: 32px;
      position: relative;
      border-right: 1px solid var(--c1);
      border-top: 1px solid var(--c1);
      .close-description {
        position: absolute;
        top: 24px;
        right: 32px;
        height: 32px;
        width: 32px;
        border-radius: 4px;
        cursor: pointer;
        background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='32' height='32' rx='4' fill='%23F5F5F5'/%3E%3Cpath d='M11.7578 19.3284L18.8289 12.2573L20.2431 13.6715L13.172 20.7426L11.7578 19.3284Z' fill='%234D4D4D'/%3E%3Crect x='13.1719' y='12.2568' width='10' height='2' transform='rotate(45 13.1719 12.2568)' fill='%234D4D4D'/%3E%3C/svg%3E%0A");
        background-position: center;
        background-repeat: no-repeat;
        transition: 300ms ease;
        &:hover {
          background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='32' height='32' rx='4' fill='%234D4D4D'/%3E%3Cpath d='M11.7577 19.3279L18.8288 12.2568L20.243 13.671L13.1719 20.7421L11.7577 19.3279Z' fill='%23F5F5F5'/%3E%3Crect x='13.172' y='12.2568' width='10' height='2' transform='rotate(45 13.172 12.2568)' fill='%23F5F5F5'/%3E%3C/svg%3E%0A");
        }
      }
    }
    .animated-content {
      position: relative;
      width: 100%;
      height: 100%;
      .animated-hand {
        position: absolute;
        width: 517px;
        height: 285px;
        top: 0;
        left: 150px;
        animation: animateHandFromLeftIdleCourseProgram 5s ease-in-out infinite alternate;
      }
      .choose-text {
        position: absolute;
        left: 150px;
        width: 650px;
        top: -104px;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  section.course-program {
    @media screen and (-webkit-device-pixel-ratio: 1.25) {
      width: 125vw;
    }
    .title {
      max-width: 394px;
    }
    .details {
      .modules .module {
        width: 390px;
      }
      .animated-content .choose-text {
        top: -168px;
        max-width: 320px;
      }
    }
  }
}

section.m-course-program {
  padding: 40px 16px;
  .modules {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    margin-top: 24px;
    .module {
      padding: 16px;
      border-radius: 16px;
      .title {
        padding-right: 48px;
      }
      .brief-desc {
        position: relative;
        .expand-button {
          position: absolute;
          top: 0;
          right: 0;
          height: 24px;
          width: 24px;
          background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='32' height='32' rx='4' fill='%23F5F5F5'/%3E%3Cpath d='M11 15.5H21V17.5H11V15.5Z' fill='%234D4D4D'/%3E%3Crect x='17' y='11.5' width='10' height='2' transform='rotate(90 17 11.5)' fill='%234D4D4D'/%3E%3C/svg%3E%0A");
          background-position: center;
          background-repeat: no-repeat;
          transition: 300ms ease;
        }
      }
      .module-description {
        display: none;
      }
      &.selected {
        padding: 16px 16px 0;
        border-top: 1px solid var(--c1);
        border-bottom: 1px solid var(--c1);
        .brief-desc {
          padding-bottom: 32px;
          border-bottom: 1px solid #e6e6e6;
          .expand-button {
            background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='32' height='32' rx='4' fill='%23F5F5F5'/%3E%3Cpath d='M11 15.5H21V17.5H11V15.5Z' fill='%234D4D4D'/%3E%3C/svg%3E%0A");
          }
        }
        .module-description {
          padding-top: 32px;
          display: block;
        }
      }
    }
  }
}

@keyframes animateHandFromLeftIdleCourseProgram {
  0% {
    transform: rotateZ(0deg);
  }
  50% {
    transform: rotateZ(-15deg);
  }
  100% {
    transform: rotateZ(15deg);
  }
}
