$animationSpeed: 40s;

@keyframes ticker {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(calc(-50% - 16px));
  }
}

section.graduates {
  width: 100vw;
  .double-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    .double-col {
      min-height: 536px;
      position: relative;
      .animated-content {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        .animated-hand {
          left: calc(50% - 200px);
          top: calc(50% - 200px);
          width: 282px;
          height: 400px;
          position: absolute;
          transform: translateX(-50%) rotateZ(13deg);
          background-size: contain;
          transform-origin: bottom center;
        }
        .triangle {
          position: absolute;
          left: calc(50% - 260px);
          top: calc(50% - 260px);
          width: 520px !important;
          height: 520px !important;
          background-size: contain;
        }
      }
      .double-box {
        width: 530px;
        padding: 200px 0 64px 50px;
      }
    }
  }
  .carousel-container {
    width: 100%;
    display: flex;
    overflow: visible;
    box-sizing: content-box;
    padding-bottom: 120px;
    .image-carousel {
      display: flex;
      box-sizing: content-box;
      gap: 32px;
      animation: $animationSpeed ticker running linear infinite;
      .image-box {
        width: 200px;
        height: 200px;
        transition: transform 0.5s ease;
        &:hover {
          transform: scale(1.2);
        }
        img {
          width: 200px;
          height: 200px;
          object-fit: contain;
        }
      }
    }
  }
}

@media screen and (-webkit-device-pixel-ratio: 1.25) {
  section.graduates {
    width: 125vw;
  }
}

section.m-graduates {
  width: 100vw;
  overflow: hidden;
  .double-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 40px 16px 0;
    .text-container {
      min-height: 172px;
    }
    .anim-container {
      min-height: 290px;
    }
    .double-col {
      position: relative;
      .animated-content {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        .animated-hand {
          left: calc(50% - 106px);
          top: calc(50% - 116px);
          width: 164px;
          height: 232px;
          position: absolute;
          transform: rotateZ(13deg);
          background-size: contain;
          transform-origin: bottom center;
        }
        .triangle {
          position: absolute;
          left: calc(50% - 150px);
          top: calc(50% - 150px);
          width: 300px !important;
          height: 300px !important;
          background-size: contain;
        }
      }
      .list-title {
        position: absolute;
        bottom: -10px;
      }
    }
  }
  .carousel-container {
    width: 100%;
    display: flex;
    overflow: visible;
    box-sizing: content-box;
    padding-bottom: 40px;
    .image-carousel {
      display: flex;
      box-sizing: content-box;
      gap: 32px;
      animation: $animationSpeed ticker running linear infinite;
      .image-box {
        width: 160px;
        height: 160px;
        overflow: hidden;
        transition: transform 0.5s ease;
        img {
          width: 160px;
          height: 160px;
          object-fit: contain;
        }
      }
    }
  }
}
