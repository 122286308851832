.module-container {
  max-width: 100vw;
    padding: 140px 120px 80px;
    .block {
        max-width: 790px;
        margin: 0 auto;
    }
    .side-by-side {
      display: grid;
      column-gap: 100px;
      grid-template-columns: repeat(2, 1fr);
    }
  }


.mob-module {
  max-width: 100vw;
  padding: 40px 16px;
}
@media screen and (min-width: 960px) and (-webkit-device-pixel-ratio: 1.25) {
  .module-container {
    max-width: 125vw;
  }
}